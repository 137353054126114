import { Navigation, SkeletonBodyText } from '@shopify/polaris';

export function AppsSectionFallback() {
  return (
    <Navigation.Section
      title='Apps'
      items={[
        {
          label: <SkeletonBodyText lines={1} />,
          key: '1',
        },
        {
          label: <SkeletonBodyText lines={1} />,
          key: '2',
        },
        {
          label: <SkeletonBodyText lines={1} />,
          key: '3',
        },
      ]}
    />
  );
}
