import imageBannerAppIcon from './assets/imageBannerLogo.png';
import timerAppIcon from './assets/timer-app-icon.png';
import trustBadgesAppIcon from './assets/trustBadgeLogo.png';
import paymentBadgesAppIcon from './assets/payment-badges-app-icon.png';

export const APP_NAME = 'Checkout Extensions';

export const appIcons = {
  'image-banner': imageBannerAppIcon,
  'payment-badges': paymentBadgesAppIcon,
  'trust-badges': trustBadgesAppIcon,
  timer: timerAppIcon,
};

export const APP_IDS = {
  imageBanner: 'image-banner',
  paymentBadges: 'payment-badges',
  timer: 'timer',
  trustBadges: 'trust-badges',
};
