import { TopBar } from '@shopify/polaris';
import SuspendedShopDropdown from './ShopDropdown';

export default function AppTopBar({
  mobileNavigationActive,
  setMobileNavigationActive,
}) {
  return (
    <TopBar
      showNavigationToggle
      onNavigationToggle={() =>
        setMobileNavigationActive(!mobileNavigationActive)
      }
      userMenu={<SuspendedShopDropdown />}
    />
  );
}
