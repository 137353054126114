import { lazy, Suspense } from 'react';
import { Frame, Loading } from '@shopify/polaris';

const SuspendedPaymentOnboarding = lazy(() =>
  import('./SuspendedPaymentOnboarding'),
);

export default function PaymentBadgesOnboarding() {
  return (
    <Frame>
      <Suspense fallback={<Loading />}>
        <SuspendedPaymentOnboarding />
      </Suspense>
    </Frame>
  );
}
