import { Suspense, useState } from 'react';
import { Image, Navigation } from '@shopify/polaris';
import { useLocation, useNavigate } from 'react-router-dom';

import classes from '../../App.module.scss';
import getSideBarIcon from '../SidebarIcon';
import { AppsSectionFallback } from './AppsSectionFallback';
import { AppInstallModal } from '../AppInstallModal';
import { appIcons } from '../../constants';

import homeIcon from '../../assets/home-icon.png';
import NavigateToAppSettingModal from '../NavigateToAppSettingModal';
import useAppsMetaDataQuery from '../../hooks/useAppsMetaDataQuery';
import useShouldRedirectToOnboarding from '../../hooks/useShouldRedirectToOnboarding';

function AppsSection({ onMobileNavigationClose }) {
  //#region states
  const navigate = useNavigate();
  const location = useLocation();
  const [appToInstall, setAppToInstall] = useState(null);
  const apps = useAppsMetaDataQuery();
  const [appIdForSettingsNavigation, setAppIdForSettingsNavigation] =
    useState(null);
  const shouldRedirectToOnboarding = useShouldRedirectToOnboarding();
  //#endregion

  return [
    <Navigation.Section
      title='Apps'
      key='navId'
      items={[
        {
          exactMatch: true,
          url: '/',
          icon: () => (
            <Image
              alt='Home icon'
              source={homeIcon}
              className={classes.navigationIconImg}
            />
          ),
          label: 'Home',
          onClick: onMobileNavigationClose,
        },
        ...apps.map(app => ({
          // url: getIsAppInstalled(app) ? `/${app.id}` : undefined,
          onClick: () => {
            if (shouldRedirectToOnboarding(app.id)) setAppToInstall(app);
            else if (!location.pathname.startsWith(`/${app.id}`)) {
              setAppIdForSettingsNavigation(app.id);
            }
            onMobileNavigationClose();
          },
          label: app.name,
          icon: getSideBarIcon(app.image || appIcons[app.id]),
        })),
      ]}
    />,
    appToInstall && (
      <AppInstallModal
        key='modal'
        open
        onCancel={() => setAppToInstall(null)}
        app={appToInstall}
        onComplete={() => {
          setAppToInstall(null);
          navigate(`/${appToInstall.id}/onboarding`, {
            state: { showSuccessMessage: true },
          });
        }}
      />
    ),
    appIdForSettingsNavigation && (
      <NavigateToAppSettingModal
        isModalOpen
        onClose={() => setAppIdForSettingsNavigation(null)}
        appId={appIdForSettingsNavigation}
      />
    ),
  ];
}

export default function SuspendedAppsSection(props) {
  return (
    <Suspense fallback={<AppsSectionFallback />}>
      <AppsSection {...props} />
    </Suspense>
  );
}
