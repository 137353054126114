import { lazy, Suspense } from 'react';
import { Frame, Loading } from '@shopify/polaris';

const SuspendedImageBannerOnboarding = lazy(() =>
  import('./SuspendedImageBannerOnboarding'),
);

export default function ImageBannerOnboarding() {
  return (
    <Frame>
      <Suspense fallback={<Loading />}>
        <SuspendedImageBannerOnboarding />
      </Suspense>
    </Frame>
  );
}
