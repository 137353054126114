import { AxiosError } from 'axios';
import axiosApi from './axiosApi';
import { APP_IDS } from '../constants';

export const getApps = ({ signal }) =>
  axiosApi.get('/apps', { signal }).then(res => res.data);
getApps.QUERY_TAGS = ['Apps'];

export const getShopDetails = () =>
  axiosApi.get('/shops').then(res => res.data);
getShopDetails.QUERY_TAGS = ['Shop'];

export const getSettings = () =>
  axiosApi.get('/settings').then(res => res.data);
getSettings.QUERY_TAGS = ['Settings'];

export const installApp = appId =>
  axiosApi.post('/apps', { id: appId }).then(({ data }) => data);

export const getDefaultTrustBadges = ({
  signal,
  queryKey: [, badgeTypeFieldValue],
}) =>
  axiosApi
    .get('/trust-badges/default-badges', {
      signal,
      params: { style: `${badgeTypeFieldValue}_content` },
    })
    .then(res => res.data);
getDefaultTrustBadges.QUERY_TAGS = ['trust_default_badges'];
getDefaultTrustBadges.selectImageSrc = (item, style) =>
  item[`${style}_content`];

export const updateTrustBadgesAppSettings = settings =>
  axiosApi.put('/trust-badges/settings', settings).then(({ data }) => data);

export const updatePaymentBadgesAppSettings = settings =>
  axiosApi.put('/payment-badges/settings', settings).then(({ data }) => data);

export const updateImageBannerAppSettings = async settings => {
  if (settings.sections) {
    const promises = [];
    for (const section of settings.sections) {
      if (section.file) {
        promises.push(
          new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.addEventListener('load', () => {
              section.fileName = section.file.name
                .split('.')
                .slice(0, -1)
                .join('.');
              section.fileData = fileReader.result;
              delete section.imageId;
              delete section.imageUrl;
              delete section.file;
              resolve();
            });
            fileReader.addEventListener('error', error => reject(error));
            fileReader.readAsDataURL(section.file);
          }),
        );
      }
    }
    await Promise.all(promises);
  }
  return axiosApi
    .put('/image-banner/settings', settings)
    .then(({ data }) => data);
};

export const getDefaultPaymentBadges = async ({
  signal,
  queryKey: [, badgeTypeFieldValue],
}) =>
  axiosApi
    .get('/payment-badges/default-badges', {
      signal,
      params: { style: `${badgeTypeFieldValue}_content` },
    })
    .then(res => res.data);
getDefaultPaymentBadges.QUERY_TAGS = ['payment_default_badges'];
getDefaultPaymentBadges.selectImageSrc = (item, style) =>
  item[`${style}_content`] || item.original_content;

export const getTrustBadgesAppSettings = ({ queryKey: [, shopName], signal }) =>
  axiosApi
    .get('/trust-badges/settings', {
      params: {
        shop: shopName,
      },
      signal,
    })
    .then(res => res.data);
getTrustBadgesAppSettings.QUERY_TAGS = ['trust_badges'];

export const getImageBannerAppSettings = ({ queryKey: [, shopName], signal }) =>
  axiosApi
    .get('/image-banner/settings', {
      params: {
        shop: shopName,
      },
      signal,
    })
    .then(res => res.data);
getImageBannerAppSettings.QUERY_TAGS = ['image_banner'];

export const getPaymentBadgesAppSettings = ({
  queryKey: [, shopName],
  signal,
}) =>
  axiosApi
    .get('/payment-badges/settings', {
      params: {
        shop: shopName,
      },
      signal,
    })
    .then(res => res.data);
getPaymentBadgesAppSettings.QUERY_TAGS = ['payment_badges'];

export const getTimerAppSettings = ({ queryKey: [, shopName], signal }) =>
  axiosApi
    .get('/timer/settings', {
      params: {
        shop: shopName,
      },
      signal,
    })
    .then(res => res.data);
getTimerAppSettings.QUERY_TAGS = ['timer'];

export const updateTimerAppSettings = settings =>
  axiosApi.put('/timer/settings', settings).then(({ data }) => data);

export const updateTimerAppStatus = isEnabled =>
  updateTimerAppSettings({ isAppEnabled: isEnabled });
export const updateTrustBadgesAppStatus = isEnabled =>
  updateTrustBadgesAppSettings({ isAppEnabled: isEnabled });
export const updatePaymentBadgesAppStatus = isEnabled =>
  updatePaymentBadgesAppSettings({ isAppEnabled: isEnabled });
export const updateImageBannerAppStatus = isEnabled =>
  updateImageBannerAppSettings({ isAppEnabled: isEnabled });

export const verifyAppStatus = appId =>
  axiosApi
    .get(`/checkout-extension/verify/${appId}`)
    .then(res => ({ ...res.data, isFailed: false }))
    .catch(err => {
      if (err.response?.status === 403) {
        return { ...err.response.data, isFailed: true };
      }

      throw err;
    });
verifyAppStatus.MUTATION_KEYS = ['VERIFY_APP_STATUS'];

export const getPlan = ({ signal }) =>
  axiosApi.get('/plans', { signal }).then(res => res.data);
getPlan.QUERY_TAGS = ['Plans'];

export const updatePlan = planId =>
  axiosApi.post('/plans', { planId }).then(res => res.data);

export const updateAppSettingsByAppId = async ({ appId, settings }) => {
  if (appId === APP_IDS.timer) {
    return updateTimerAppSettings(settings);
  }

  if (appId === APP_IDS.trustBadges) {
    return updateTrustBadgesAppSettings(settings);
  }

  if (appId === APP_IDS.paymentBadges) {
    return updatePaymentBadgesAppSettings(settings);
  }

  if (appId === APP_IDS.imageBanner) {
    return updateImageBannerAppSettings(settings);
  }

  throw new Error('App not found!');
};

/**
 * @returns {Promise<string>}
 */
export const getPreviewLinkForApp = async () =>
  axiosApi
    .get('/checkout-extension/preview')
    .then(res => res.data.link)
    .catch(err => {
      if (
        err instanceof AxiosError &&
        err.response?.status === 403 &&
        err.response?.data?.code === 'PASSWORD_PROTECTED'
      ) {
        return null;
      }

      throw err;
    });
getPreviewLinkForApp.QUERY_TAGS = ['preview_link'];

export const updateAppInstalledAt = async ({ appId }) =>
  axiosApi
    .put(`apps/${appId}`, {
      installedAt: new Date().toISOString(),
    })
    .then(res => res.data);
