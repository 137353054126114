import { Suspense } from 'react';
import { Card, SkeletonBodyText } from '@shopify/polaris';
import AppsTable from './AppTable';

function AppsTableFallback() {
  return (
    <Card.Section>
      <SkeletonBodyText lines={3} />
    </Card.Section>
  );
}

export default function SuspendedAppsTable() {
  return (
    <Card>
      <Suspense fallback={<AppsTableFallback />}>
        <AppsTable />
      </Suspense>
    </Card>
  );
}
