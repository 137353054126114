import {
  Heading,
  Stack,
  ProgressBar,
  Modal,
  Text,
  TextStyle,
  Button,
} from '@shopify/polaris';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import classes from './VerifyStatusModal.module.scss';
import { verifyAppStatus as verifyAppStatusMutation } from '../../../libs/apiRequests';
// import VerifySteps from './components/VerifySteps';
import VerificationErrorBanner from './components/VerificationErrorBanner';

export default function VerifyStatusModal({
  open,
  onCancel,
  appId,
  markAsEnabled,
  markAsEnabledLoading,
  renderSetupStep,
  totalSteps,
  nextLoading,
  onNext, // (step, onComplete) => void
}) {
  //#region states
  const [step, setStep] = useState(1);
  const {
    mutate: verifyStatus,
    isLoading: verifyStatusLoading,
    data: verifyAppStatusRes,
    isSuccess,
    reset,
  } = useMutation(verifyAppStatusMutation, {
    onSuccess: data => {
      if (data.isCheckoutExtensionInUse) {
        reset();
        markAsVerified();
      }
    },
    mutationKey: [verifyAppStatusMutation.MUTATION_KEYS, appId],
  });
  const isLoading = verifyStatusLoading || markAsEnabledLoading;
  //#endregion

  //#region handlers
  const markAsVerified = () => {
    markAsEnabled();
  };

  const onVerifyClicked = () => {
    verifyStatus(appId);
  };
  //#endregion

  // ui
  const extraPrimaryActions = [
    {
      content: 'Back',
      disabled: isLoading || step === 1,
      onAction: () => setStep(step - 1),
    },
  ];
  if (isSuccess) {
    extraPrimaryActions.push({
      content: 'I have verified manually',
      onAction: markAsVerified,
      disabled: verifyStatusLoading,
      loading: markAsEnabledLoading,
    });
  }
  const modalPrimaryAction =
    step === totalSteps
      ? {
          disabled: markAsEnabledLoading,
          loading: verifyStatusLoading,
          id: 'verifyStatusActiveBtn',
          content: 'Verify',
          onAction: onVerifyClicked,
        }
      : {
          id: 'nextStepBtn',
          content: 'Next',
          onAction: () => {
            if (onNext) {
              onNext(step, () => {
                setStep(step + 1);
              });
            } else {
              setStep(step + 1);
            }
          },
          loading: nextLoading,
        };
  const progressPercent = Math.ceil((step / totalSteps) * 100);

  const stepMarkup = open && renderSetupStep(step);

  const modalTitleMarkup = (
    <Stack vertical spacing='tight'>
      <Heading element='h1'>Setup guide</Heading>
      <div className={classes.flex}>
        <Text variant='bodySm' as='span'>
          <TextStyle variation='subdued'>
            Step {step} of {totalSteps}
          </TextStyle>
        </Text>
        <ProgressBar size='small' progress={progressPercent} />
      </div>
    </Stack>
  );

  return (
    <Modal
      key='content'
      title={modalTitleMarkup}
      open={open}
      onClose={() => {
        onCancel();
        setStep(1);
      }}
      footer={
        <Stack alignment='center' distribution='equalSpacing'>
          <Stack alignment='center'>
            <Button plain disabled={isLoading} url='/support' id='iNeedHelpBtn'>
              I need help
            </Button>
          </Stack>
          <Stack alignment='center'>
            {extraPrimaryActions.map(({ onAction, content, ...props }) => (
              <Stack.Item key={content}>
                <Button {...props} onClick={onAction}>
                  {content}
                </Button>
              </Stack.Item>
            ))}
            <Button
              primary
              {...modalPrimaryAction}
              onClick={modalPrimaryAction.onAction}
            >
              {modalPrimaryAction.content}
            </Button>
          </Stack>
        </Stack>
      }
      noScroll
    >
      <Modal.Section>
        <div className={classes.stepContainer}>
          <Stack vertical>
            {stepMarkup}
            {isSuccess && (
              <VerificationErrorBanner mutationResponse={verifyAppStatusRes} />
            )}
            {/*<VerifySteps />*/}
            {/*<span className={classes.changesToBeDoneLastText}>*/}
            {/*  If you need any help, we would be happy to do it free for you.*/}
            {/*</span>*/}
          </Stack>
        </div>
      </Modal.Section>
    </Modal>
  );
}
