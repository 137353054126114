import { Stack, Text } from '@shopify/polaris';
import classes from './index.module.scss';
import useShopJSON from '../../hooks/useShopJSON';

export default function IntroBanner() {
  const { shop_owner: showOwner } = useShopJSON();

  return (
    <div className={classes.banner}>
      <Stack vertical spacing='tight'>
        <span className={classes.helloText}>
          <Text as='span' variant='headingLg'>
            Hello {showOwner}
          </Text>
        </span>
        <Text as='span' variant='heading4xl'>
          Improve your checkout conversion with our beautiful checkout Apps
        </Text>
      </Stack>
    </div>
  );
}
