import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const isTestingEnv = process.env.NODE_ENV === 'test';

export const queryClient = new QueryClient({
  logger: {
    log: console.log,
    warn: console.warn,
    // ✅ no more errors on the console for tests
    error: isTestingEnv ? () => {} : console.error,
  },
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: isTestingEnv ? Infinity : undefined,
      suspense: true,
      useErrorBoundary: true,
      retry: isTestingEnv ? false : undefined,
    },
    mutations: {
      useErrorBoundary: true,
      retry: isTestingEnv ? false : undefined,
      cacheTime: isTestingEnv ? Infinity : undefined,
    },
  },
});

export function ReactQueryClientProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
