import { lazy, Suspense } from 'react';
import { Frame, Loading } from '@shopify/polaris';

const SuspendedTimerOnboarding = lazy(() =>
  import('./SuspendedTimerOnboarding'),
);

export default function TrustBadgesOnboarding() {
  return (
    <Frame>
      <Suspense fallback={<Loading />}>
        <SuspendedTimerOnboarding />
      </Suspense>
    </Frame>
  );
}
