import { lazy, Suspense } from 'react';
import { Loading } from '@shopify/polaris';

const SuspendedTrustBadgesPage = lazy(() =>
  import('./SuspendedTrustBadgesPage'),
);

export default function TrustBadgesPage() {
  return (
    <Suspense fallback={<Loading />}>
      <SuspendedTrustBadgesPage />
    </Suspense>
  );
}

export const SuspendedTrustBadgesSettingsPage = lazy(() =>
  import('./TrustBadgesSettingsFormPage'),
);
