import {
  CalloutCard,
  Icon,
  Layout,
  Stack,
  Heading,
  TextStyle,
} from '@shopify/polaris';
import { StarFilledMinor } from '@shopify/polaris-icons';
import classes from './index.module.scss';

import reviewIllustration from '../../assets/leaveUseReviewIllustration.jpg';

export default function SupportCards() {
  return (
    <div className={classes.supportCardsContainer}>
      <Layout>
        <Layout.Section>
          <CalloutCard
            title={
              <div className={classes.calloutCardTitle}>
                <Stack
                  wrap={false}
                  spacing='tight'
                  alignment='center'
                  distribution='leading'
                >
                  <TextStyle variation='positive'>
                    <Icon source={StarFilledMinor} />
                  </TextStyle>
                  <Heading as='h2'>Let us know how we are doing</Heading>
                </Stack>
              </div>
            }
            illustration={reviewIllustration}
            primaryAction={{
              content: 'Rate Now',
              id: 'rateNowBtn',
              url: 'https://apps.shopify.com/checkout-jelly#modal-show=ReviewListingModal',
              external: true,
            }}
          >
            <p>
              We would be delighted to know how our app is helping you to
              improve your checkout experience.
            </p>
          </CalloutCard>
        </Layout.Section>
      </Layout>
    </div>
  );
}
