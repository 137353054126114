import { Button } from '@shopify/polaris';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { updateTimerAppStatus } from '../../libs/apiRequests';
import VerifyStatusModal from '../../pages/TimerApp/useAppToggleFlow/VerifyStatusModal';
import AutoClosableToast from '../AutoClosableToast';
import AppEnabledModal from '../../pages/TimerApp/useAppToggleFlow/AppEnabledModal';
import ConfirmAppStatusChangeModal from '../../pages/TimerApp/useAppToggleFlow/ConfirmAppStatusChangeModal';
import useAppToggleFlow from '../../pages/TimerApp/useAppToggleFlow';
import { APP_IDS } from '../../constants';

export default function ToggleAppStatusButton({
  isAppEnabled,
  isAppEnabledInDb,
  appName = APP_IDS.timer,
  updateAppStatusFn = updateTimerAppStatus,
  renderSetupStep,
  totalSteps,
  onNext,
  nextLoading,
  renderButton,
}) {
  //#region states
  const [isConfirmEnableModalOpen, setIsConfirmEnableModalOpen] =
    useState(false);
  const location = useLocation();
  const {
    startFlow,
    verifyStatusModalProps,
    appEnabledModalProps,
    appDisabledToastProps,
    confirmAppStatusModalProps,
  } = useAppToggleFlow(appName);
  const { isLoading: isToggleAppLoading, mutate: mutateAppStatus } =
    useMutation(updateAppStatusFn, {
      onSuccess: () => {
        startFlow();
        setIsConfirmEnableModalOpen(false);
      },
    });

  useEffect(() => {
    if (!location.state?.showSuccessMessage) {
      return;
    }

    mutateAppStatus(true, {
      onSuccess: () => startFlow(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  //#endregion

  //#region handlers
  const onPrimaryActionClicked = () => {
    // if app is both enabled from our side and live on checkout
    if (isAppEnabled) {
      // this will show modal to disable app
      startFlow();
    } else if (!isAppEnabledInDb) {
      // App is disabled, so do the api call
      // this will trigger the flow
      // mutateAppStatus(true);
      setIsConfirmEnableModalOpen(true);
    } else {
      // app is not live on checkout, so need to show verify modal
      startFlow();
    }
  };
  //#endregion

  return (
    <>
      {renderButton ? (
        renderButton(isToggleAppLoading, onPrimaryActionClicked)
      ) : (
        <Button
          primary
          destructive={isAppEnabled}
          onClick={onPrimaryActionClicked}
          loading={isToggleAppLoading}
        >
          {isAppEnabled ? 'Disable' : 'Enable'}
        </Button>
      )}

      <VerifyStatusModal
        {...verifyStatusModalProps}
        totalSteps={totalSteps}
        renderSetupStep={renderSetupStep}
        onNext={onNext}
        nextLoading={nextLoading}
      />
      <AutoClosableToast {...appDisabledToastProps} />
      <AppEnabledModal {...appEnabledModalProps} />
      <ConfirmAppStatusChangeModal {...confirmAppStatusModalProps} />
      <ConfirmAppStatusChangeModal
        isOpen={isConfirmEnableModalOpen}
        isAppEnabled={isAppEnabled}
        isLoading={isToggleAppLoading}
        onCancel={() => setIsConfirmEnableModalOpen(false)}
        onConfirm={() => mutateAppStatus(true)}
      />
    </>
  );
}
