import { Suspense, lazy } from 'react';
import { Loading } from '@shopify/polaris';

const TimerApp = lazy(() => import('./index'));

export default function TimerAppWithSuspense() {
  return (
    <Suspense fallback={<Loading />}>
      <TimerApp />
    </Suspense>
  );
}

export const SuspendedTimerSettingsPage = lazy(() =>
  import('./TimerSettingsPage'),
);
