import { useMemo } from 'react';
import { useAppMetaData } from './useAppsMetaDataQuery';

export default function useAppEnabledStatus(appId) {
  const appGlobalSettings = useAppMetaData(appId);

  const isAppEnabled = useMemo(
    () =>
      appGlobalSettings.isAppEnabled &&
      appGlobalSettings.isCheckoutExtensionInUse,
    [
      appGlobalSettings.isAppEnabled,
      appGlobalSettings.isCheckoutExtensionInUse,
    ],
  );

  return [isAppEnabled, appGlobalSettings];
}
