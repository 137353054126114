import { lazy, Suspense } from 'react';
import { Loading } from '@shopify/polaris';

const SuspendedPaymentBadgesPage = lazy(() =>
  import('./SuspendedPaymentBadgesPage'),
);

export default function PaymentBadgesPage() {
  return (
    <Suspense fallback={<Loading />}>
      <SuspendedPaymentBadgesPage />
    </Suspense>
  );
}

export const SuspendedPaymentBadgesSettingsPage = lazy(() =>
  import('./PaymentBadgesSettingsFormPage'),
);
