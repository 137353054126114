import { Button } from '@shopify/polaris';
import classes from './PrimaryBlueButton.module.scss';

export default function PrimaryBlueButton(props) {
  return (
    <div className={classes.container}>
      <Button {...props} primary />
    </div>
  );
}
