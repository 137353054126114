/* eslint-disable react/no-unstable-nested-components */
import { Navigation, Image } from '@shopify/polaris';
import { useLocation } from 'react-router-dom';

import classes from '../../App.module.scss';
import SuspendedAppsSection from './SuspendedAppsSection';
// import pricingIcon from '../../assets/pricing-icon.png';
import customerSupportIcon from '../../assets/customer-support-icon.png';
// import helpIcon from '../../assets/help-icon.png';

export default function AppNavigation({ onMobileNavigationClose }) {
  const location = useLocation();

  return (
    <div className={classes.navigationContainer}>
      <Navigation location={location.pathname}>
        <div className={classes.appNavigationSection__container}>
          <SuspendedAppsSection
            onMobileNavigationClose={onMobileNavigationClose}
          />
        </div>
        <Navigation.Section
          items={[
            // {
            //   url: '/pricing',
            //   icon: () => (
            //     <Image
            //       className={classes.navigationIconImg}
            //       alt='pricing link icon'
            //       source={pricingIcon}
            //     />
            //   ),
            //   label: 'Pricing',
            //   onClick: onMobileNavigationClose,
            // },
            {
              url: '/support',
              icon: () => (
                <Image
                  className={classes.navigationIconImg}
                  alt='customer support icon'
                  source={customerSupportIcon}
                />
              ),
              label: 'Contact us',
              onClick: onMobileNavigationClose,
            },
            // {
            //   url: '/faqs',
            //   icon: () => (
            //     <Image
            //       className={classes.navigationIconImg}
            //       alt='Faqs icon'
            //       source={helpIcon}
            //     />
            //   ),
            //   label: 'Help',
            //   onClick: onMobileNavigationClose,
            // },
          ]}
          title='Support'
        />
      </Navigation>
    </div>
  );
}
