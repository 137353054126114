import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { getApps } from '../libs/apiRequests';

export default function useAppsMetaDataQuery() {
  const { data } = useQuery(getApps.QUERY_TAGS, getApps);

  return data;
}

export function useAppMetaData(appId) {
  const appsMetaData = useAppsMetaDataQuery();

  if (!appId) {
    return null;
  }

  return appsMetaData.find(app => app.id === appId);
}

export const useUpdateAppMetaDataInCache = appId => {
  const queryClient = useQueryClient();
  const appsMetaData = useAppsMetaDataQuery();

  return useCallback(
    data => {
      queryClient.setQueryData(
        getApps.QUERY_TAGS,
        appsMetaData.map(app => {
          if (app.id !== appId) {
            return app;
          }
          return {
            ...app,
            ...data,
          };
        }),
      );
    },
    [appId, appsMetaData, queryClient],
  );
};
