import { lazy, Suspense } from 'react';
import { Frame, Loading } from '@shopify/polaris';

const SuspendedSetupSuccessPage = lazy(() =>
  import('./SuspendedSetupSuccessPage'),
);

export default function SetupSuccessPage() {
  return (
    <Frame>
      <Suspense fallback={<Loading />}>
        <SuspendedSetupSuccessPage />
      </Suspense>
    </Frame>
  );
}
