import { useCallback, useState } from 'react';

export const modalTypes = {
  verify: 'verify',
  appEnable: 'appEnable',
  confirmStatus: 'confirmStatus',
};

const useModalFunctions = (setModalState, modalType) =>
  useCallback(() => setModalState(modalType), [modalType, setModalState]);

const useFlowModals = () => {
  const [openModal, setOpenModal] = useState(null);

  const openVerifyModal = useModalFunctions(setOpenModal, modalTypes.verify);
  const openAppEnableModal = useModalFunctions(
    setOpenModal,
    modalTypes.appEnable,
  );
  const openConfirmStatusModal = useModalFunctions(
    setOpenModal,
    modalTypes.confirmStatus,
  );
  const closeAllModals = useCallback(() => setOpenModal(null), []);

  return {
    verifyStatusModalOpen: openModal === modalTypes.verify,
    openVerifyModal,
    appEnabledModalOpen: openModal === modalTypes.appEnable,
    openAppEnableModal,
    confirmModalOpen: openModal === modalTypes.confirmStatus,
    openConfirmStatusModal,
    closeAllModals,
  };
};

export default useFlowModals;
