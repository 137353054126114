import { lazy, Suspense } from 'react';
import { Card, Layout, Loading } from '@shopify/polaris';

const SetupSteps = lazy(() => import('./PaymentBadgesExtensionSetupSteps'));

export default function PaymentBadgesExtensionSetupStepsPage() {
  return (
    <Suspense fallback={<Loading />}>
      <Layout>
        <Layout.AnnotatedSection title='Setup steps'>
          <Card sectioned>
            <SetupSteps isOnSettingsPage />
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Suspense>
  );
}
