import { Image, Modal, Stack, Text } from '@shopify/polaris';

import classes from './AppEnabledModal.module.scss';
import greenTickImage from '../../../assets/green-tick.png';

export default function AppEnabledModal({ isOpen, onCancel }) {
  return (
    <Modal
      title='App is enabled!'
      small
      titleHidden
      open={isOpen}
      onClose={onCancel}
    >
      <div data-testid='modal-body'>
        <Modal.Section>
          <div className={classes.container}>
            <Stack vertical distribution='center' alignment='center'>
              <Image
                className={classes.image}
                source={greenTickImage}
                alt={null}
              />
              <Text as='span' variant='bodyLg'>
                Hurray! The app is live on your store!
              </Text>
            </Stack>
          </div>
        </Modal.Section>
      </div>
    </Modal>
  );
}
