import {
  updateImageBannerAppStatus,
  updatePaymentBadgesAppStatus,
  updateTimerAppStatus,
  updateTrustBadgesAppStatus,
} from '../libs/apiRequests';
import { APP_IDS } from '../constants';

const getUpdateAppStatusFn = appName => {
  if (appName === APP_IDS.timer) {
    return updateTimerAppStatus;
  }
  if (appName === APP_IDS.trustBadges) {
    return updateTrustBadgesAppStatus;
  }
  if (appName === APP_IDS.paymentBadges) {
    return updatePaymentBadgesAppStatus;
  }
  if (appName === APP_IDS.imageBanner) {
    return updateImageBannerAppStatus;
  }

  throw new Error('App not found!');
};

export default getUpdateAppStatusFn;
