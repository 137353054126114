import { Banner } from '@shopify/polaris';

export default function VerificationErrorBanner({
  mutationResponse: { code, isCheckoutExtensionInUse, isFailed },
}) {
  let message;
  if (!isFailed) {
    if (isCheckoutExtensionInUse) {
      return null;
    }

    message =
      'Checkout extension is not added properly. So please follow the below steps again and verify again';
  } else if (code === 'NO_CHECKOUT' || code === 'NO_PRODUCT') {
    message =
      'Store checkout is disabled. Kindly enable it and verify again or go to the store checkout and verify manually';
  } else if (code === 'PASSWORD_PROTECTED') {
    message =
      'Store is password protected. So please remove the password or you can go to store checkout and verify manually';
  } else {
    message = 'Verification failed! Please try again';
  }

  return <Banner status='critical'>{message}</Banner>;
}
