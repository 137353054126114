import { lazy, Suspense } from 'react';
import { Loading } from '@shopify/polaris';

const SuspendedSupportPage = lazy(() => import('./SuspendedSupportPage'));

export default function SupportPage() {
  return (
    <Suspense fallback={<Loading />}>
      <SuspendedSupportPage />
    </Suspense>
  );
}
