import { useMemo, useState } from 'react';
import { Card, EmptyState, Stack, Tabs } from '@shopify/polaris';
import classNames from 'classnames';
import AppTableRow from './AppTableRow';
// import AppTableSort from './AppTableSort';
import classes from './AppTable.module.scss';
import useAppsMetaDataQuery from '../../../hooks/useAppsMetaDataQuery';

function sortByName(app1, app2) {
  return app1.name.localeCompare(app2.name);
}

function sortByInstallDate(app1, app2) {
  return (
    (app1.installedAt ? new Date(app1.installedAt) : Infinity) -
    (app2.installedAt ? new Date(app2.installedAt) : Infinity)
  );
}

function sortApps(appsData, selectedSort) {
  return [...appsData].sort((app1, app2) => {
    switch (selectedSort) {
      case 'NAME_DESC':
        return -1 * sortByName(app1, app2);
      case 'DATE_INSTALLED_DESC':
        return -1 * sortByInstallDate(app1, app2);
      case 'DATE_INSTALLED_ASC':
        return sortByInstallDate(app1, app2);
      case 'NAME_ASC':
      default:
        return sortByName(app1, app2);
    }
  });
}

const tabs = [
  {
    id: 'all',
    content: 'All',
    panelID: 'all-content',
    filterApps: apps => apps,
  },
  {
    id: 'installed',
    content: 'Installed',
    panelID: 'installed-content',
    filterApps: apps => apps.filter(app => Boolean(app.installedAt)),
  },
  {
    id: 'pending',
    content: 'Pending',
    panelID: 'pending-content',
    filterApps: apps => apps.filter(app => !app.installedAt),
  },
];

export default function AppsTable() {
  const appsData = useAppsMetaDataQuery();
  const [
    selectedSort,
    // setSelectedSort
  ] = useState('NAME_ASC');
  const [selectedTab, setSelectedTab] = useState(0);
  const apps = useMemo(
    () => sortApps(tabs[selectedTab].filterApps(appsData), selectedSort),
    [appsData, selectedSort, selectedTab],
  );

  return (
    <Card>
      <Card.Section flush>
        <div
          className={classNames(
            'Polaris-ResourceItem__Container',
            classes.tabsContainer,
          )}
        >
          <Stack alignment='center' wrap={false}>
            <Stack.Item fill>
              <div className={classes.tabsContainer}>
                <Tabs
                  selected={selectedTab}
                  tabs={tabs}
                  onSelect={newVal => setSelectedTab(newVal)}
                />
              </div>
            </Stack.Item>
            {/*<Stack.Item>*/}
            {/*  <AppTableSort*/}
            {/*    value={selectedSort}*/}
            {/*    onChange={selected => setSelectedSort(selected)}*/}
            {/*  />*/}
            {/*</Stack.Item>*/}
          </Stack>
        </div>
      </Card.Section>
      <Card.Section flush>
        {apps.length > 0 ? (
          <ul className='Polaris-ResourceList'>
            {apps.map(app => (
              <AppTableRow key={app.id} app={app} />
            ))}
          </ul>
        ) : (
          <EmptyState
            image='https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png'
            heading='No apps found'
          />
        )}
      </Card.Section>
    </Card>
  );

  // return [
  //   <div className='Polaris-ResourceList__ResourceListWrapper' key='table'>
  //     <div className='Polaris-ResourceList__HeaderOuterWrapper'>
  //       <div>
  //         <div>
  //           <div className='Polaris-ResourceList__HeaderWrapper Polaris-ResourceList__HeaderWrapper--hasSort'>
  //             <div className='Polaris-ResourceList__HeaderContentWrapper'>
  //               <div className='Polaris-ResourceList__HeaderTitleWrapper'>
  //                 Showing {apps.length} {apps.length === 1 ? 'App' : 'Apps'}
  //               </div>
  //               <div className='Polaris-ResourceList__SortWrapper'>
  //                 <AppTableSort
  //                   value={selectedSort}
  //                   onChange={selected => setSelectedSort(selected)}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <ul className='Polaris-ResourceList'>
  //       {apps.map(app => (
  //         <AppTableRow key={app.id} app={app} />
  //       ))}
  //     </ul>
  //   </div>,
  // ];
}
