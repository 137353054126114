/* eslint-disable no-nested-ternary */
import { Badge } from '@shopify/polaris';
import useIsAppInstalled from '../hooks/useIsAppInstalled';
import classes from './AppBadgeStatus.module.scss';
import { useAppMetaData } from '../hooks/useAppsMetaDataQuery';

export default function AppBadgeStatus({ appId }) {
  const app = useAppMetaData(appId);
  const isAppInstalled = useIsAppInstalled(appId);
  let appStatus = 'Not Installed';

  if (isAppInstalled) {
    if (app.isCheckoutExtensionInUse) {
      if (!app.isAppEnabled) {
        appStatus = 'Disabled';
      } else {
        appStatus = 'Live';
      }
    } else {
      appStatus = 'Pending';
    }
  }

  return (
    <span className={classes.container}>
      <Badge
        status={
          appStatus === 'Not Installed'
            ? 'critical'
            : appStatus === 'Live'
            ? 'success'
            : 'warning'
        }
        progress='complete'
      >
        {appStatus}
      </Badge>
    </span>
  );
}
