import { AxiosError } from 'axios';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { EmptyState, Page } from '@shopify/polaris';
import classes from './ErrorBoundary.module.scss';

import somethingWentWrongImg from './assets/error-illustration.png';
import sessionExpiredImg from './assets/session-expired-illustration.jpg';

function ErrorFallback({ error }) {
  if (error instanceof AxiosError) {
    if (error.response?.status === 401) {
      return (
        <Page>
          <EmptyState
            image={sessionExpiredImg}
            heading='Session expired!'
            imageContained
          >
            Please try to open the app again from Shopify admin panel.
          </EmptyState>
        </Page>
      );
    }
  }

  return (
    <Page>
      <EmptyState
        image={somethingWentWrongImg}
        heading='Something went wrong!'
        imageContained
        action={{
          content: 'Refresh page',
          onAction: () => {
            window.location.reload();
          },
        }}
      >
        We encountered some error! Please try refreshing the page or contact us
        from the chat bubble.
      </EmptyState>
    </Page>
  );
}

export default function ErrorBoundary({ children }) {
  return (
    <div className={classes.container}>
      <ReactErrorBoundary FallbackComponent={ErrorFallback}>
        {children}
      </ReactErrorBoundary>
    </div>
  );
}
