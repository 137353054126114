import { useCallback, useState } from 'react';
import { Toast } from '@shopify/polaris';

export function useAutoClosableToastProps(content) {
  const [toastVisible, setToastVisible] = useState(false);

  const openToast = useCallback(() => setToastVisible(true), []);
  const closeToast = useCallback(() => setToastVisible(false), []);

  return [{ visible: toastVisible, content, onClose: closeToast }, openToast];
}

export default function AutoClosableToast({
  content,
  visible,
  onClose,
  ...props
}) {
  return (
    visible && (
      <Toast {...props} content={content} onDismiss={() => onClose()} />
    )
  );
}
