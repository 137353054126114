import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function ScrollTop() {
  const location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line no-multi-assign
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [location]);

  return null;
}
