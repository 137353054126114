import axios from 'axios';

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const getToken = () => axiosApi.defaults.headers.common.Authorization;

export const updateAuthToken = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const keyName = 'token';
  let token = '';

  if (searchParams.has(keyName)) {
    token = searchParams.get(keyName);
  } else if (sessionStorage.getItem(keyName)) {
    token = sessionStorage.getItem(keyName);
  } else if (process.env.REACT_APP_ENV === 'local') {
    token = process.env.REACT_APP_JWT;
  }
  sessionStorage.setItem(keyName, token);

  axiosApi.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default axiosApi;
