/* eslint-disable react/no-unstable-nested-components */
import { Suspense, useState } from 'react';
import {
  Icon,
  SkeletonDisplayText,
  Popover,
  ActionList,
  Image,
} from '@shopify/polaris';
import { CaretDownMinor } from '@shopify/polaris-icons';
import useShopJSON from '../../hooks/useShopJSON';

import classes from './index.module.scss';
// import pricingIcon from '../../assets/pricing-icon.png';
import customerSupportIcon from '../../assets/customer-support-icon.png';
// import helpIcon from '../../assets/help-icon.png';

function PageIcon(props) {
  return <Image {...props} style={{ maxWidth: '100%' }} />;
}

function ShopDropdown() {
  //#region states
  const [popoverActive, setPopoverActive] = useState(false);
  const shop = useShopJSON();
  const togglePopover = () => setPopoverActive(!popoverActive);
  //#endregion

  return (
    <div data-testid='dropdownContainer'>
      <Popover
        active={popoverActive}
        onClose={togglePopover}
        activator={
          <div
            data-testid='popover-activator'
            className={classes.userMenu}
            role='button'
            onClick={togglePopover}
            onKeyDown={null}
            onFocus={null}
            tabIndex='0'
          >
            <span>
              <span
                title={shop.myshopify_domain}
                className={classes.topBarShopName}
              >
                {shop.myshopify_domain}
              </span>
              <Icon source={CaretDownMinor} />
            </span>
          </div>
        }
      >
        <div data-testid='popover'>
          <ActionList
            items={[
              // {
              //   content: 'Pricing',
              //   url: '/pricing',
              //   onAction: togglePopover,
              //   icon: () => (
              //     <PageIcon alt='Pricing icon' source={pricingIcon} />
              //   ),
              // },
              {
                content: 'Contact us',
                url: '/support',
                onAction: togglePopover,
                icon: () => (
                  <PageIcon
                    alt='Support page icon'
                    source={customerSupportIcon}
                  />
                ),
              },
              // {
              //   content: 'Help',
              //   url: '/faqs',
              //   onAction: togglePopover,
              //   icon: () => <PageIcon alt='Pricing icon' source={helpIcon} />,
              // },
            ]}
          />
        </div>
      </Popover>
    </div>
  );
}

function ShopDropdownFallback() {
  return (
    <span className={classes.fallbackContainer}>
      <SkeletonDisplayText size='small' />
    </span>
  );
}

export default function SuspendedShopDropdown() {
  return (
    <Suspense fallback={<ShopDropdownFallback />}>
      <ShopDropdown />
    </Suspense>
  );
}
