import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button } from '@shopify/polaris';
import NavigateToAppSettingModal from '../../../components/NavigateToAppSettingModal';
import PrimaryBlueButton from '../../../components/PrimaryBlueButton';
import useShouldRedirectToOnboarding from '../../../hooks/useShouldRedirectToOnboarding';

export default function NavigateToAppSettingsButton({ id }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const shouldRedirectToOnboarding = useShouldRedirectToOnboarding();
  const navigate = useNavigate();
  const isOnboardingPending = shouldRedirectToOnboarding(id);

  const onClick = () => {
    if (isOnboardingPending) navigate(`/${id}/onboarding`);
    else setIsModalOpen(true);
  };

  return (
    <>
      {isOnboardingPending ? (
        <PrimaryBlueButton onClick={onClick}>Continue</PrimaryBlueButton>
      ) : (
        <Button primary onClick={onClick}>
          Settings
        </Button>
      )}
      <NavigateToAppSettingModal
        isModalOpen={isModalOpen}
        appId={id}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
