import getIsAppInstalled from '../utils/getIsAppInstalled';
import { useAppMetaData } from './useAppsMetaDataQuery';

export default function useIsAppInstalled(appId) {
  const app = useAppMetaData(appId);

  if (!app) {
    throw Error(`App with id ${appId} not found`);
  }

  return getIsAppInstalled(app);
}
