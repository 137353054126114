import { useCallback } from 'react';
import useAppsMetaDataQuery from './useAppsMetaDataQuery';
import getIsAppInstalled from '../utils/getIsAppInstalled';

export default function useShouldRedirectToOnboarding() {
  const appsMetaData = useAppsMetaDataQuery();

  return useCallback(
    appId => {
      const appMeta = appsMetaData.find(app => app.id === appId);

      return !getIsAppInstalled(appMeta) || !appMeta.isCheckoutExtensionInUse;
    },
    [appsMetaData],
  );
}
