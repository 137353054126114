import { Text } from '@shopify/polaris';
import classes from '../TopRibbon.module.scss';

export default function TopRibbon() {
  return (
    <div
      data-testid='ribbon-container'
      className={classes.topRibbon__container}
    >
      <div className={classes.topRibbon}>
        <Text variant='bodyMd' as='span'>
          Customize your checkout with our beautiful checkout apps
        </Text>
      </div>
    </div>
  );
}
