/* eslint-disable no-param-reassign */
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider as ShopifyAppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { ReactQueryClientProvider } from './libs/reactQuery';

import '@shopify/polaris/build/esm/styles.css';
import './index.scss';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import AppLoading from './components/AppLoading';
import ShopifyLink from './components/ShopifyLink';
import { updateAuthToken } from './libs/axiosApi';

const runApp = async () => {
  await updateAuthToken();
  if (window.sessionStorage.getItem('isPlanUpgradeWasClicked'))
    window.history.pushState?.(null, null, '/pricing');

  ReactDOM.render(
    <StrictMode>
      <BrowserRouter>
        <ReactQueryClientProvider>
          <ShopifyAppProvider i18n={enTranslations} linkComponent={ShopifyLink}>
            <ErrorBoundary>
              <Suspense
                fallback={<AppLoading className='loadingPageContainer' />}
              >
                <App />
              </Suspense>
            </ErrorBoundary>
          </ShopifyAppProvider>
        </ReactQueryClientProvider>
      </BrowserRouter>
    </StrictMode>,
    document.getElementById('root'),
  );
};

runApp().catch(err => {
  console.error(err);
});
