import { Frame } from '@shopify/polaris';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import ScrollTop from '../components/ScrollTop';
import TopRibbon from '../components/TopRibbon';
import AppTopBar from '../components/AppTopBar';
import AppNavigation from '../components/AppNavigation';

import logo from '../assets/logo.jpg';

const logoConfig = {
  url: '/',
  topBarSource: logo,
  // contextualSaveBarSource: logo,
};

export default function AppLayout({ isFreshInstall }) {
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  return (
    <>
      <ScrollTop />
      {!isFreshInstall && <TopRibbon />}
      <Frame
        logo={logoConfig}
        topBar={
          <AppTopBar
            mobileNavigationActive={mobileNavigationActive}
            setMobileNavigationActive={setMobileNavigationActive}
          />
        }
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={() => setMobileNavigationActive(false)}
        navigation={
          !isFreshInstall && (
            <AppNavigation
              onMobileNavigationClose={() => setMobileNavigationActive(false)}
            />
          )
        }
      >
        <Outlet />
      </Frame>
    </>
  );
}
