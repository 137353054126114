import {
  Stack,
  TextStyle,
  ResourceItem,
  Heading,
  Thumbnail,
} from '@shopify/polaris';
import classes from '../index.module.scss';
import { appIcons } from '../../../constants';
import AppToggleSwitch from '../../../components/AppToggleSwitch';
import NavigateToAppSettingsButton from './NavigateToAppSettingsButton';
import getIsAppInstalled from '../../../utils/getIsAppInstalled';
import StartInstallationBtn from './StartInstallationBtn';
import AppBadgeStatus from '../../../components/AppBadgeStatus';

export default function AppTableRow({ app }) {
  //#region states
  const {
    id,
    name,
    image,
    // isAppEnabled,
    isCheckoutExtensionInUse,
    description,
  } = app;
  // const isAppLive = isAppEnabled && isCheckoutExtensionInUse;
  const isAppInstalled = getIsAppInstalled(app);
  //#endregion

  return (
    <div className={classes.resourceItemContainer}>
      <ResourceItem
        id={id}
        accessibilityLabel={`View details for ${name}`}
        persistActions
      >
        <div className={classes.itemContainer}>
          <Thumbnail
            alt={null}
            size='small'
            source={image || appIcons[id]}
            data-testid={`image-${id}`}
          />
          <Stack vertical spacing='none'>
            <Stack vertical={false}>
              <span className={classes.appName}>
                <Heading element='h2'>{name}</Heading>
              </span>
              <AppBadgeStatus appId={app.id} />
            </Stack>
            <span className={classes.appDescription}>
              <TextStyle variation='subdued'>
                {description || 'App description'}
              </TextStyle>
            </span>
          </Stack>
          <div className={classes.appSwitchContainer}>
            {isCheckoutExtensionInUse ? (
              <AppToggleSwitch appId={id} />
            ) : (
              <span />
            )}
          </div>
          <span className={classes.appBtnContainer}>
            {isAppInstalled ? (
              <NavigateToAppSettingsButton id={id} />
            ) : (
              <StartInstallationBtn app={app} />
            )}
          </span>
        </div>
      </ResourceItem>
    </div>
  );
}
