import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { getApps, updateAppSettingsByAppId } from '../../../libs/apiRequests';
import useShopJSON from '../../../hooks/useShopJSON';

export function useUpdateAppSettingsMutation(appId, onToggleStatusSuccess) {
  const queryClient = useQueryClient();
  const shopJson = useShopJSON();
  // This mutation will occure only when
  // 1 - App was enabled and wants to disable
  // 2 - isAppEnabled was true and now verified manually
  const { mutate, ...mutationResult } = useMutation(updateAppSettingsByAppId, {
    onSuccess: (data, ...args) => {
      const appSettingsGetQueryKeys = [appId, shopJson.myshopify_domain];
      queryClient.setQueryData(appSettingsGetQueryKeys, data);
      // Update getApps query cache
      queryClient.setQueryData(
        getApps.QUERY_TAGS,
        queryClient.getQueryData(getApps.QUERY_TAGS)?.map(app => {
          if (app.id !== appId) {
            return app;
          }

          return {
            ...app,
            isCheckoutExtensionInUse: data.isAppEnabled
              ? data.isAppEnabled
              : app.isCheckoutExtensionInUse, // Update checkoutExtensionInUse status
            isAppEnabled: data.isAppEnabled, // update app enabled status
          };
        }),
      );

      return onToggleStatusSuccess?.(data, ...args);
    },
  });
  const mutateFn = useCallback(
    settings =>
      mutate({
        appId,
        settings,
      }),
    [appId, mutate],
  );

  return {
    ...mutationResult,
    mutate: mutateFn,
  };
}
