import Switch from './Switch';
import ToggleAppStatusButton from './ToggleAppStatusButton';
import useAppEnabledStatus from '../hooks/useAppEnabledStatus';
import getUpdateAppStatusFn from '../utils/getUpdateAppStatusFn';

export default function AppToggleSwitch({ appId }) {
  const [isAppEnabled, { isAppEnabled: isAppEnabledInDb }] =
    useAppEnabledStatus(appId);

  return (
    <ToggleAppStatusButton
      appName={appId}
      isAppEnabled={isAppEnabled}
      isAppEnabledInDb={isAppEnabledInDb}
      updateAppStatusFn={getUpdateAppStatusFn(appId)}
      renderButton={(isLoading, onClick) => (
        <Switch
          loading={isLoading}
          checked={isAppEnabled}
          onChange={() => onClick()}
        />
      )}
    />
  );
}
