import { lazy, Suspense } from 'react';
import { Frame, Loading } from '@shopify/polaris';

const SuspendedTrustBadgesOnboarding = lazy(() =>
  import('./SuspendedTrustBadgesOnboarding'),
);

export default function TrustBadgesOnboarding() {
  return (
    <Frame>
      <Suspense fallback={<Loading />}>
        <SuspendedTrustBadgesOnboarding />
      </Suspense>
    </Frame>
  );
}
