import { Navigate, Route, Routes } from 'react-router-dom';

import { useLayoutEffect } from 'react';
import classes from './App.module.scss';

import HomePage from './pages/Home';
import TimerAppWithSuspense, {
  SuspendedTimerSettingsPage,
} from './pages/TimerApp/TimerAppWithSuspense';
// import SuspendedPricingPage from './pages/Pricing/SuspendedPricingPage';
import SupportPage from './pages/Support';
import PaymentBadgesPage, {
  SuspendedPaymentBadgesSettingsPage,
} from './pages/PaymentBadges';
import TrustBadgesPage, {
  SuspendedTrustBadgesSettingsPage,
} from './pages/TrustBadges';
import ImageBannerPage, {
  SuspendedImageBannerSettingsPage,
} from './pages/ImageBanner';
import TrustBadgesOnboarding from './pages/TrustBadges/TrustBadgesOnboarding';
import TimerOnboarding from './pages/TimerApp/TimerOnboarding';
import PaymentBadgesOnboarding from './pages/PaymentBadges/PaymentBadgesOnboarding';
import ImageBannerOnboarding from './pages/ImageBanner/ImageBannerOnboarding';
import AppLayout from './pages/AppLayout';
import TimerExtensionSetupStepsPage from './pages/TimerApp/TimerExtensionSetupStepsPage';
import TrustBadgesExtensionSetupStepsPage from './pages/TrustBadges/TrustBadgesExtensionSetupStepsPage';
import PaymentBadgesExtensionSetupStepsPage from './pages/PaymentBadges/PaymentBadgesExtensionSetupStepsPage';
import ImageBannerExtensionSetupStepsPage from './pages/ImageBanner/ImageBannerExtensionSetupStepsPage';
import SetupSuccessPage from './pages/SetupSuccessPage';
import useShopJSON from './hooks/useShopJSON';
import WelcomePage from './pages/WelcomePage';

function App() {
  const shopJson = useShopJSON();
  const isFreshInstall =
    new URLSearchParams(window.location.search).get('fresh_install') === '1';

  useLayoutEffect(() => {
    window.$crisp = [];

    window.CRISP_WEBSITE_ID = '9a70b14e-b8db-48b2-8773-95eb66b91cee';

    (function initializeCrisp() {
      const d = document;

      const s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';

      s.async = 1;

      d.getElementsByTagName('head')[0].appendChild(s);

      window.$crisp.push([
        'set',
        'session:segments',
        [['checkout-extensions']],
      ]);

      window.$crisp.push([
        'set',
        'session:data',
        ['storename', shopJson.myshopify_domain],
      ]);
    })();
  }, [shopJson.myshopify_domain]);

  return (
    <div className={classes.container}>
      <Routes>
        <Route path='*' element={<Navigate to='/' />} />
        <Route path='' element={<AppLayout isFreshInstall={isFreshInstall} />}>
          <Route
            index
            element={isFreshInstall ? <WelcomePage /> : <HomePage />}
          />
          {/*<Route path='/pricing' element={<SuspendedPricingPage />} />*/}
          <Route path='/timer' element={<TimerAppWithSuspense />}>
            <Route index element={<SuspendedTimerSettingsPage />} />
            <Route
              path='checkout-info'
              element={<TimerExtensionSetupStepsPage />}
            />
          </Route>
          <Route path='/payment-badges' element={<PaymentBadgesPage />}>
            <Route index element={<SuspendedPaymentBadgesSettingsPage />} />
            <Route
              path='checkout-info'
              element={<PaymentBadgesExtensionSetupStepsPage />}
            />
          </Route>
          <Route path='/image-banner' element={<ImageBannerPage />}>
            <Route index element={<SuspendedImageBannerSettingsPage />} />
            <Route
              path='checkout-info'
              element={<ImageBannerExtensionSetupStepsPage />}
            />
          </Route>
          <Route path='/trust-badges' element={<TrustBadgesPage />}>
            <Route index element={<SuspendedTrustBadgesSettingsPage />} />
            <Route
              path='checkout-info'
              element={<TrustBadgesExtensionSetupStepsPage />}
            />
          </Route>
          <Route path='/support' element={<SupportPage />} />
        </Route>
        <Route
          path='trust-badges/onboarding'
          element={<TrustBadgesOnboarding />}
        />
        <Route path='timer/onboarding' element={<TimerOnboarding />} />
        <Route
          path='payment-badges/onboarding'
          element={<PaymentBadgesOnboarding />}
        />
        <Route
          path='image-banner/onboarding'
          element={<ImageBannerOnboarding />}
        />
        <Route path=':appId/setup-success' element={<SetupSuccessPage />} />
      </Routes>
    </div>
  );
}

export default App;
