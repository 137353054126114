import { Heading, Modal, TextStyle } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import classes from './NavigateToAppSettingsModal.module.scss';
import PrimaryBlueButton from './PrimaryBlueButton';
import useNavigateInNewTab from '../hooks/useNavigateInNewTab';
import { useAppMetaData } from '../hooks/useAppsMetaDataQuery';

function Row({ index, title, desc, btnProps: { content, ...btnProps } }) {
  return (
    <div className={classes.itemContainer}>
      <div>
        <span className={classes.index}>{index}</span>
      </div>
      <div className={classes.textContainer}>
        <Heading element='h4'>{title}</Heading>
        <span>{desc}</span>
      </div>
      <span className={classes.buttonContainer}>
        <PrimaryBlueButton {...btnProps} primary>
          {content}
        </PrimaryBlueButton>
      </span>
    </div>
  );
}

export default function NavigateToAppSettingModal({
  isModalOpen,
  onClose,
  appId,
}) {
  const navigate = useNavigate();
  const navigateInNewTab = useNavigateInNewTab();
  const appMeta = useAppMetaData(appId);

  return (
    <Modal
      open={isModalOpen}
      title={
        <span>
          <TextStyle variation='strong'>{appMeta.name}:</TextStyle> Choose
          setting type
        </span>
      }
      onClose={onClose}
    >
      <Modal.Section>
        <Row
          btnProps={{
            onClick: () => {
              navigate(`/${appId}`);
              onClose();
            },
            content: 'Open',
          }}
          btn={<PrimaryBlueButton primary>Navigate</PrimaryBlueButton>}
          index={1}
          title='App Settings'
          desc='Configure the app settings in the app settings page'
        />
      </Modal.Section>
      <Modal.Section>
        <Row
          index={2}
          title='Checkout Settings'
          desc='Configure the app visibility in the store checkout pages'
          btnProps={{
            onClick: () => {
              navigateInNewTab(`/${appId}/checkout-info`);
              onClose();
            },
            content: 'Open',
          }}
        />
      </Modal.Section>
    </Modal>
  );
}
