import { Loading } from '@shopify/polaris';
import { lazy, Suspense } from 'react';

const LazyImageBannerPage = lazy(() => import('./SuspendedImageBannerPage'));

export default function ImageBannerPage() {
  return (
    <Suspense fallback={<Loading />}>
      <LazyImageBannerPage />
    </Suspense>
  );
}

export const SuspendedImageBannerSettingsPage = lazy(() =>
  import('./ImageBannerSettingsFormPage'),
);
