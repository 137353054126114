import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAutoClosableToastProps } from '../../../components/AutoClosableToast';
import useAppEnabledStatus from '../../../hooks/useAppEnabledStatus';
import useFlowModals from './useFlowModals';
import { useUpdateAppSettingsMutation } from './useUpdateAppSettingsMutation';
import { useUpdateAppMetaDataInCache } from '../../../hooks/useAppsMetaDataQuery';

export default function useAppToggleFlow(appId, onSuccess) {
  //#region states
  const navigate = useNavigate();
  const {
    confirmModalOpen,
    verifyStatusModalOpen,
    appEnabledModalOpen,
    openAppEnableModal,
    openConfirmStatusModal,
    // openVerifyModal,
    closeAllModals,
  } = useFlowModals();

  const [appDisabledToastProps, showAppDisabledToast] =
    useAutoClosableToastProps('App disabled successfully!');

  // noinspection JSUnusedLocalSymbols
  const { isLoading: toggleStatusMutationLoading, mutate: mutateAppSettings } =
    useUpdateAppSettingsMutation(appId, (data, variables) => {
      closeAllModals();
      // if (!variables.settings.isAppEnabled) {
      if (!data.isAppEnabled) {
        showAppDisabledToast();
      } else {
        openAppEnableModal();
      }

      onSuccess?.(data);
    });

  const [isAppEnabled, { isCheckoutExtensionInUse }] =
    useAppEnabledStatus(appId);

  const appEnabledModalProps = useMemo(
    () => ({
      isOpen: appEnabledModalOpen,
      setIsOpen: openAppEnableModal,
      onCancel: closeAllModals,
    }),
    [appEnabledModalOpen, closeAllModals, openAppEnableModal],
  );

  const verifyStatusModalProps = useMemo(
    () => ({
      appId,
      open: verifyStatusModalOpen,
      onCancel: closeAllModals,
      markAsEnabledLoading: toggleStatusMutationLoading,
      markAsEnabled: () => mutateAppSettings({ isAppVerifiedManually: true }),
    }),
    [
      appId,
      closeAllModals,
      mutateAppSettings,
      toggleStatusMutationLoading,
      verifyStatusModalOpen,
    ],
  );

  const onConfirm = useCallback(() => {
    if (isAppEnabled) {
      mutateAppSettings({ isAppEnabled: false });
    } else {
      navigate(`/${appId}/onboarding`);
      // openVerifyModal();
    }
  }, [appId, isAppEnabled, mutateAppSettings, navigate]);

  const confirmAppStatusModalProps = useMemo(
    () => ({
      isOpen: confirmModalOpen,
      onCancel: closeAllModals,
      isAppEnabled,
      isLoading: toggleStatusMutationLoading,
      onConfirm,
    }),
    [
      closeAllModals,
      confirmModalOpen,
      isAppEnabled,
      onConfirm,
      toggleStatusMutationLoading,
    ],
  );
  const updateAppMetaDataInCache = useUpdateAppMetaDataInCache(appId);
  //#endregion

  //#region handlers
  const startFlow = () => {
    if (isAppEnabled) {
      openConfirmStatusModal();
    } else if (isCheckoutExtensionInUse) {
      openAppEnableModal();
      updateAppMetaDataInCache({
        isAppEnabled: true,
      });
      onSuccess?.({ isAppEnabled: true });
    } else {
      navigate(`/${appId}/onboarding`);
      // openVerifyModal();
    }
  };
  //#endregion

  return {
    appEnabledModalProps,
    verifyStatusModalProps,
    confirmAppStatusModalProps,
    startFlow,
    appDisabledToastProps,
  };
}
