export default function getSideBarIcon(icon) {
  return function SidebarIcon(props) {
    return (
      <div
        style={{
          backgroundImage: `url(${icon})`,
          backgroundSize: 'contain',
          width: '100%',
          height: '100%',
        }}
        {...props}
      />
    );
  };
}
