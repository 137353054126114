import { Modal } from '@shopify/polaris';

export default function ConfirmAppStatusChangeModal({
  isAppEnabled,
  isOpen,
  onCancel,
  isLoading,
  onConfirm,
}) {
  return (
    <Modal
      small
      open={isOpen}
      title={`Are you sure you want to ${
        isAppEnabled ? 'disable' : 'enable'
      } this app?`}
      onClose={!isLoading ? onCancel : undefined}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onCancel,
          disabled: isLoading,
        },
      ]}
      primaryAction={{
        content: 'Yes',
        loading: isLoading,
        onAction: () => onConfirm(),
      }}
      sectioned
    >
      This action will {isAppEnabled ? 'disable' : 'enable'} this app.
    </Modal>
  );
}
