import { Modal, TextContainer } from '@shopify/polaris';
import useAppInstallMutation from '../hooks/useAppInstallMutation';

export function AppInstallModal({ open, onCancel, onComplete, app }) {
  const appId = app.id;
  const { mutate, isLoading } = useAppInstallMutation(appId, {
    onSuccess: () => {
      onComplete();
    },
  });

  return (
    <Modal
      onClose={onCancel}
      open={open}
      small
      title='Do you want to enable the app?'
      secondaryActions={[
        {
          id: 'cancelBtn',
          content: 'Cancel',
          onAction: () => onCancel(),
        },
      ]}
      primaryAction={{
        id: 'activateBtn',
        loading: isLoading,
        content: 'Activate',
        onAction: () => {
          if (app.isInstallionStarted) {
            onComplete();
          } else mutate(appId);
        },
      }}
    >
      <div data-testid='install-modal-body'>
        <Modal.Section>
          <TextContainer>
            <p>This app is not activated. If you are sure, please confirm.</p>
          </TextContainer>
        </Modal.Section>
      </div>
    </Modal>
  );
}
