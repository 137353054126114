import { useNavigate } from 'react-router-dom';
import useAppInstallMutation from '../../../hooks/useAppInstallMutation';
import PrimaryBlueButton from '../../../components/PrimaryBlueButton';

export default function StartInstallationBtn({
  app: { id, isInstallionStarted },
}) {
  const navigate = useNavigate();
  const navigateToOnboarding = () =>
    navigate(`/${id}/onboarding`, { state: { showSuccessMessage: true } });
  const { mutate, isLoading } = useAppInstallMutation(id, {
    onSuccess: () => {
      navigateToOnboarding();
    },
  });

  return (
    <PrimaryBlueButton
      loading={isLoading}
      onClick={() => {
        if (!isInstallionStarted) mutate(id);
        else navigateToOnboarding();
      }}
    >
      Install
    </PrimaryBlueButton>
  );
}
