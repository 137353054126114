import { lazy, Suspense } from 'react';
import { Loading } from '@shopify/polaris';

const SuspendedWelcomePage = lazy(() => import('./SuspendedWelcomePage'));

export default function WelcomePage() {
  return (
    <Suspense fallback={<Loading />}>
      <SuspendedWelcomePage />
    </Suspense>
  );
}
