import { Page, Stack } from '@shopify/polaris';
import classes from './index.module.scss';

import SuspendedAppsTable from './AppsTable';
import SupportCards from './SupportCards';
import IntroBanner from './IntroBanner';

export default function HomePage() {
  return (
    <div className={classes.container}>
      <Page>
        <div className={classes.stackContainer}>
          <Stack vertical spacing='loose'>
            <IntroBanner />
            <SuspendedAppsTable />
            <SupportCards />
          </Stack>
        </div>
      </Page>
    </div>
  );
}
