import { useMutation } from '@tanstack/react-query';
import { installApp } from '../libs/apiRequests';
import { useUpdateAppMetaDataInCache } from './useAppsMetaDataQuery';

export default function useAppInstallMutation(appId, mutationProps = {}) {
  const updateAppMetaDataInCache = useUpdateAppMetaDataInCache(appId);
  return useMutation(installApp, {
    ...mutationProps,
    onSuccess: data => {
      updateAppMetaDataInCache({
        isInstallionStarted: true,
      });

      mutationProps.onSuccess?.(data);
    },
  });
}
